<template>
    <div class="order-management-container">
        <h1 class="title">{{ $t('navbar.orderManagement') }}</h1>
        <FilterSection class="filter-section" />

        <div v-if="loading">
            <div class="text-center">Kraunama...</div>
        </div>
        <div v-else>
            <TableHeader v-if="checkOuts.length" class="table-header" />
            <div v-if="!checkOuts.length" class="empty__wrapper">
                <h1>{{ $t('orderManagement.noOrdersYet') }}</h1>
                <CRButton v-if="!isAdmin" @click="$router.push({ name: 'allMealKits' })">
                    {{ $t('mealKits.title') }}
                </CRButton>
            </div>
            <div v-else class="check-out-item__wrapper">
                <CheckOutItem
                    class="item"
                    v-for="checkOut in checkOuts"
                    :key="checkOut.id"
                    :check-out="checkOut"
                    @openConfirmationModal="handelOpenConfirmationModal"
                />
            </div>
        </div>

        <Pagination
            v-show="checkOuts.length && !loading"
            :current-page="meta.currentPage"
            :page-count="meta.pageCount"
            :total-count="meta.totalCount"
            :per-page="meta.perPage"
            @pageNum="changePage"
        />

        <ConfirmationModal
            v-if="isConfirmationModalOpen"
            :title="confirmationModalTitle"
            :is-loading="isLoading"
            @close="closeConfirmationModal"
            @confirm="updateStatus"
        />
    </div>
</template>

<script>
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import FilterSection from '@/components/OrderManagement/FilterSection.vue';
    import TableHeader from '@/components/OrderManagement/TableHeader.vue';
    import CheckOutItem from '@/components/OrderManagement/CheckOutItem.vue';
    import Pagination from '@/components/Common/Pagination/Pagination.vue';
    import ConfirmationModal from '@/components/Modals/ConfirmationModal.vue';
    import { orderManagementSectionsMixin } from '@/utils/mixins';
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'AllCheckOutsPage',
        components: { FilterSection, TableHeader, CheckOutItem, CRButton, Pagination, ConfirmationModal },
        mixins: [orderManagementSectionsMixin],
        data() {
            return {
                selectedCheckOutId: null,
            };
        },
        computed: {
            ...mapGetters('orderManagement', ['checkOuts', 'meta', 'loading']),
            ...mapGetters('auth', ['isAdmin']),
        },
        methods: {
            ...mapActions('orderManagement', ['getAllCheckOuts']),

            handelOpenConfirmationModal(data) {
                this.selectedCheckOutId = data.id;
                this.openConfirmationModal(data.status);
            },

            async updateStatus() {
                this.isLoading = true;

                try {
                    const payload = {
                        id: this.selectedCheckOutId,
                        data: { status: this.status },
                    };

                    await this.updateCheckOut(payload);
                } catch (error) {
                    this.$filters.toast(error.message);
                } finally {
                    this.closeConfirmationModal();
                    this.selectedCheckOutId = null;
                    this.isLoading = false;
                }
            },

            async changePage(pageNum) {
                console.log(pageNum);
                try {
                    await this.getAllCheckOuts({ page: pageNum });
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },
        },
        async mounted() {
            try {
                await this.getAllCheckOuts();
            } catch (error) {
                this.$filters.toast(error.message);
            }
        },
    };
</script>

<style lang="scss" scoped>
    .order-management-container {
        padding-top: 20px;
        padding-bottom: 25px;

        .title {
            display: none;
        }

        .filter-section {
            margin-bottom: 20px;
        }

        .check-out-item__wrapper {
            @include row-align-start;
            flex-wrap: wrap;

            .item {
                width: 100%;
                margin-bottom: 20px;
            }
        }

        .empty__wrapper {
            @include column-align-center;

            margin-top: 150px;
        }
    }

    @include media($md) {
        .order-management-container {
            .check-out-item__wrapper {
                .item {
                    width: 49%;

                    margin-right: 2%;

                    &:nth-of-type(2n) {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    @include media($lg) {
        .order-management-container {
            h1 {
                display: block;

                margin-bottom: 32px;
            }

            .check-out-item__wrapper {
                .item {
                    width: 100%;

                    margin-right: 0;
                }
            }
        }
    }

    @include media($xlg) {
        .order-management-container {
            .check-out-item__wrapper {
                .item {
                    width: 49%;

                    margin-right: 2%;

                    &:nth-of-type(2n) {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    @include media($xl) {
        .order-management-container {
            .check-out-item__wrapper {
                .item {
                    width: 32%;

                    margin-right: 2%;

                    &:nth-of-type(2n) {
                        margin-right: 2%;
                    }

                    &:nth-of-type(3n) {
                        margin-right: 0;
                    }
                }
            }

            .empty__wrapper {
                margin-top: 250px;
            }
        }
    }

    @include media($xxl) {
        .order-management-container {
            .check-out-item__wrapper {
                .item {
                    width: 100%;

                    margin-right: 0%;

                    &:nth-of-type(2n) {
                        margin-right: 0%;
                    }
                }
            }

            .empty__wrapper {
                margin-top: 350px;
            }
        }
    }
</style>
